import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Nav from "../components/detailedNav";
import image23 from "../resources/home/image23.png";
import image24 from "../resources/home/image24.png";
import image25 from "../resources/home/image25.png";
import image26 from "../resources/home/image26.png";
import image27 from "../resources/home/image27.png";
import image28 from "../resources/home/image28.png";
import image29 from "../resources/home/image29.png";
import image30 from "../resources/home/image30.png";
import image31 from "../resources/home/image31.png";
import image32 from "../resources/home/image32.png";
import Bg from "../resources/detailedHome/Bg.png";
import Footer from "../components/detailedFooter";
import Dropdown from "../components/dropdown";
import UploadButton from "../components/fileUploaderButton";

const Main = () => {
  const [headerData, setHeaderData] = useState("Default");
  const bib = useParams();
  console.log(bib.heading);
  // setHeaderData(bib.heading)
  const cleanHeading = (heading) =>
    heading.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-");

  const dummyData = [
    {
      image: image23,
      heading: "Production/ Manufacturing",
    },
    {
      image: image24,
      heading: "Supply Chain Management",
    },
    {
      image: image25,
      heading: "Research and Development",
    },
    {
      image: image26,
      heading: "Marketing and Brand Management",
    },
    {
      image: image27,
      heading: "Sales",
    },
    {
      image: image28,
      heading: "Human Resources (HR)",
    },
    {
      image: image29,
      heading: "Procurement",
    },
    {
      image: image30,
      heading: "Information Technology (IT)",
    },
    {
      image: image31,
      heading: "Legal and Compliance",
    },
    {
      image: image32,
      heading: "Finance",
    },
  ];
  useEffect(() => {
    dummyData.forEach((item) => {
      if (cleanHeading(item.heading) == bib.heading) {
        setHeaderData(item.heading); // Set the matching heading
      }
    });
  }, [bib.heading]);

  //below for 1st upload button
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("Selected file:", file);

    if (file) {
      // Create a new FormData instance and append the file
      const formData = new FormData();
      formData.append("file", file);

      localStorage.setItem("upload1", formData);
    }
  };

  // below for 2nd uplaod button
  const fileInputRef2 = useRef(null);

  const handleButtonClick2 = () => {
    fileInputRef.current.click();
  };

  const handleFileChange2 = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    console.log("Selected file:", file);

    if (file) {
      // Create a new FormData instance and append the file
      const formData = new FormData();
      formData.append("file", file);

      localStorage.setItem("upload2", formData);

      alert(`Successfully stored in localstorage.`);
    }
  };

  return (
    <>
      {/* <h1>DETAILED</h1> */}
      <div
        className="flex justify-center"
        style={{ fontFamily: "Poppins, sans-serif" }}
      >
        <div
          className="w-full max-w-screen-2xl"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <Nav prop={headerData} />
          <div
            className="bg-cover bg-center  min-h-screen bg-[#006064]"
            style={{ backgroundImage: `url(${Bg})` }}
          >
            <div className="flex justify-center">
              <div className=" lg:w-[55%] lg:flex justify-between">
                <div>
                  <div
                    className="text-white my-2"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Your Data
                  </div>
                  <div>
                    <div className="flex">
                      <Dropdown />
                      <div>
                        <button
                          onClick={handleButtonClick}
                          className="bg-[#00ACC1] hover:bg-[#0497a9] text-white py-1 px-4 rounded-2xl flex items-center mx-2"
                          style={{ boxShadow: "2px 2px 2px  #191A23" }}
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.79232 9.33325V3.56034L3.95065 5.402L2.95898 4.37492L6.50065 0.833252L10.0423 4.37492L9.05065 5.402L7.20898 3.56034V9.33325H5.79232ZM2.25065 12.1666C1.86107 12.1666 1.52768 12.028 1.25048 11.7508C0.97329 11.4736 0.834457 11.14 0.833984 10.7499V8.62492H2.25065V10.7499H10.7507V8.62492H12.1673V10.7499C12.1673 11.1395 12.0287 11.4731 11.7515 11.7508C11.4743 12.0285 11.1407 12.1671 10.7507 12.1666H2.25065Z"
                              fill="white"
                            />
                          </svg>

                          <span className="ml-2">Upload</span>
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          accept=".xlsx"
                          style={{ display: "none" }} // Hides the file input
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="text-white my-2"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Compititors Data
                  </div>
                  <div>
                    <div className="flex">
                      <Dropdown />
                      <div>
                        <button
                          onClick={handleButtonClick2}
                          className="bg-[#00ACC1] hover:bg-[#0497a9] text-white py-1 px-4 rounded-2xl flex items-center mx-2"
                          style={{ boxShadow: "2px 2px 2px  #191A23" }}
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.79232 9.33325V3.56034L3.95065 5.402L2.95898 4.37492L6.50065 0.833252L10.0423 4.37492L9.05065 5.402L7.20898 3.56034V9.33325H5.79232ZM2.25065 12.1666C1.86107 12.1666 1.52768 12.028 1.25048 11.7508C0.97329 11.4736 0.834457 11.14 0.833984 10.7499V8.62492H2.25065V10.7499H10.7507V8.62492H12.1673V10.7499C12.1673 11.1395 12.0287 11.4731 11.7515 11.7508C11.4743 12.0285 11.1407 12.1671 10.7507 12.1666H2.25065Z"
                              fill="white"
                            />
                          </svg>

                          <span className="ml-2">Upload</span>
                        </button>
                        <input
                          type="file"
                          ref={fileInputRef2}
                          onChange={handleFileChange2}
                          accept=".xlsx"
                          style={{ display: "none" }} // Hides the file input
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-sm flex items-center justify-center text-white mt-10">
              <div
                className="w-[99vw] max-sm:w-full max-sm:px-2 text-center"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                {/* As a user, you are responsible for harmonizing your dataset and
                the competitor dataset. This involves ensuring that both
                datasets have compatible structures, formats, and values. Proper
                harmonization is vital for generating accurate and insightful
                analyses. */}
              </div>
            </div>
            <div className="flex justify-center mt-14">
            
              <div className="bg-red-300 w-[66%] aspect-square flex flex-wrap border-8 rounded-md border-gray-500 overflow-hidden">
                <div className="h-[50%] w-[50%] bg-black"></div>
                <div className="h-[50%] w-[50%] bg-red-700"></div>
                <div className="h-[50%] w-[50%] bg-green-700"></div>
                <div className="h-[50%] w-[50%] bg-blue-700"></div>
              </div>
            </div>

            <div className="flex justify-center py-20">
              <div className="sm:flex  items-center">
                <div className="flex items-center justify-center">
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 43 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.8125 30.9062H29.5625V33.5938H18.8125V30.9062ZM13.4375 30.9062H16.125V33.5938H13.4375V30.9062ZM18.8125 24.1875H29.5625V26.875H18.8125V24.1875ZM13.4375 24.1875H16.125V26.875H13.4375V24.1875ZM18.8125 17.4688H29.5625V20.1562H18.8125V17.4688ZM13.4375 17.4688H16.125V20.1562H13.4375V17.4688Z"
                      fill="#2D313D"
                    />
                    <path
                      d="M33.5938 6.71875H29.5625V5.375C29.5625 4.66223 29.2794 3.97865 28.7753 3.47465C28.2713 2.97065 27.5878 2.6875 26.875 2.6875H16.125C15.4122 2.6875 14.7287 2.97065 14.2247 3.47465C13.7206 3.97865 13.4375 4.66223 13.4375 5.375V6.71875H9.40625C8.69348 6.71875 8.0099 7.0019 7.5059 7.5059C7.0019 8.0099 6.71875 8.69348 6.71875 9.40625V37.625C6.71875 38.3378 7.0019 39.0213 7.5059 39.5253C8.0099 40.0294 8.69348 40.3125 9.40625 40.3125H33.5938C34.3065 40.3125 34.9901 40.0294 35.4941 39.5253C35.9981 39.0213 36.2812 38.3378 36.2812 37.625V9.40625C36.2812 8.69348 35.9981 8.0099 35.4941 7.5059C34.9901 7.0019 34.3065 6.71875 33.5938 6.71875ZM16.125 5.375H26.875V10.75H16.125V5.375ZM33.5938 37.625H9.40625V9.40625H13.4375V13.4375H29.5625V9.40625H33.5938V37.625Z"
                      fill="#2D313D"
                    />
                  </svg>

                  <div className="font-bold mx-3" style={{ fontFamily: 'Poppins, sans-serif'}}>Download Results</div>
                </div>
                <div className="mx-3 max-sm:mt-5">
                  <div className="flex">
                    <Dropdown />
                    <UploadButton prop="Download"/>
                  </div>
                </div>
              </div>
            </div>
            {/* Red overlay div */}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Main;
