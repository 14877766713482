import Home from "./decisivePulseAI/pages/Home"
import { Routes,Route } from "react-router-dom";
import Login from './decisivePulseAI/pages/loginVar4'
// import './style.css'
import './index.css'
import DetailedHomeDecisionAI from './decisivePulseAI/pages/detailedHomeCard'
import Admin from './decisivePulseAI/pages/admin'

const App=()=> {
  return (
    <>
    

    <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route
          path="/:heading"
          element={<DetailedHomeDecisionAI />}
        ></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/admin" element={<Admin/>}></Route>
        

    </Routes>


    </>
  );
}

export default App;
