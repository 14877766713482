import Logo from "../resources/home/Group121.png";
import { Link } from "react-router-dom";

const Main = (prop) => {
  const isAdmin = prop.prop == "Admin"; // Check if the prop is "admin"

  return (
    <>
      <div className="flex items-center justify-between bg-[#006064] py-4 px-6">
        <div className="flex items-center">
          <Link to="/"><img src={Logo} alt="Logo" className="w-20 mr-2" /></Link>
        </div>

        {/* admin text */}
        {/* if admin into prop remove max-md:hidden */}
        <div
          className={`flex justify-center w-full ${
            isAdmin ? "" : "max-md:hidden"
          }`}
        >
          <div className="w-1/2 flex items-center justify-center text-white text-xl space-x-2">
            <h3>{prop.prop}</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                d="M7.29289 13.7071C7.68342 14.0976 8.31658 14.0976 8.70711 13.7071L15.0711 7.34315C15.4616 6.95262 15.4616 6.31946 15.0711 5.92893C14.6805 5.53841 14.0474 5.53841 13.6569 5.92893L8 11.5858L2.34315 5.92893C1.95262 5.53841 1.31946 5.53841 0.928932 5.92893C0.538408 6.31946 0.538407 6.95262 0.928932 7.34315L7.29289 13.7071ZM7 -4.37114e-08L7 13L9 13L9 4.37114e-08L7 -4.37114e-08Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <Link to="/admin">
          <button className="text-white bg-[#00ACC1] p-3 rounded-[50%]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 21 22"
              fill="none"
              className="mx-auto -mb-1.5"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83333 4.88889C5.83333 3.59227 6.325 2.34877 7.20017 1.43192C8.07534 0.515078 9.26232 0 10.5 0C11.7377 0 12.9247 0.515078 13.7998 1.43192C14.675 2.34877 15.1667 3.59227 15.1667 4.88889C15.1667 6.1855 14.675 7.42901 13.7998 8.34586C12.9247 9.2627 11.7377 9.77778 10.5 9.77778C9.26232 9.77778 8.07534 9.2627 7.20017 8.34586C6.325 7.42901 5.83333 6.1855 5.83333 4.88889ZM5.83333 12.2222C4.28624 12.2222 2.80251 12.8661 1.70854 14.0121C0.614582 15.1582 0 16.7126 0 18.3333C0 19.3058 0.368749 20.2384 1.02513 20.9261C1.6815 21.6137 2.57174 22 3.5 22H17.5C18.4283 22 19.3185 21.6137 19.9749 20.9261C20.6313 20.2384 21 19.3058 21 18.3333C21 16.7126 20.3854 15.1582 19.2915 14.0121C18.1975 12.8661 16.7138 12.2222 15.1667 12.2222H5.83333Z"
                fill="white"
              />
            </svg>
            <span className="text-[10px] hover:underline">Admin</span>
          </button>
        </Link>
      </div>

      {/* admin text resp */}
      {/* if admin into prop add hidden */}
      <div
        className={`flex justify-center w-full md:hidden bg-[#006064] py-10 ${
          isAdmin ? "hidden" : ""
        }`}
      >
        <div className="w-1/2 max-sm:w-full flex items-center justify-center text-white text-xl space-x-2">
          <span>{prop.prop}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
          >
            <path
              d="M7.29289 13.7071C7.68342 14.0976 8.31658 14.0976 8.70711 13.7071L15.0711 7.34315C15.4616 6.95262 15.4616 6.31946 15.0711 5.92893C14.6805 5.53841 14.0474 5.53841 13.6569 5.92893L8 11.5858L2.34315 5.92893C1.95262 5.53841 1.31946 5.53841 0.928932 5.92893C0.538408 6.31946 0.538407 6.95262 0.928932 7.34315L7.29289 13.7071ZM7 -4.37114e-08L7 13L9 13L9 4.37114e-08L7 -4.37114e-08Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Main;
